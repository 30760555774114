import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { config } from './SliderByContentConfig'
import PictureImgix from '@/components/PictureImgix'
import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import styles from './SliderByContent.module.scss'
import useDeviceType from '@/hooks/useDeviceType'

const SliderByContent = ({
    backgroundColorClass = 'u-bgColor--contrastWhite',
    className,
    imageSide = 'right',
    productCode,
    sectionName
}) => {
    const classes = classNames(`section`, backgroundColorClass, className)
    const { isMobile } = useDeviceType('mobile')
    const [scrollIndex, setScrollIndex] = useState(0)

    const contentClasses = classNames(`${styles.contentContainer} col--md-6 col--xs-12 u-textLeft`, {
        [styles['contentContainer--paddingRight']]: imageSide === 'right',
        [styles['contentContainer--paddingLeft']]: imageSide === 'left'
    })

    const dotsClasses = classNames({ 
        [styles.dotsRight]: imageSide === 'right',
        [styles.dotsLeft]: imageSide === 'left'
    })

    const imageClasses = classNames(`${styles.imageContainer} col--md-6 col--xs-12`)

    const productData = config[productCode]

    const title = () => {
        return <h2 className={`t-heading2 ${styles.title}`}>{productData[scrollIndex].title}</h2>
    }

    const content = () => {
        const productContent = productData[scrollIndex].content()
        return (
            <ul className={styles.list}>
                {productContent.map((item, index) =>
                    <li key={`${sectionName}-content-${index}`} className='u-marginBottom'>{item}</li>
                )}
            </ul>
        )
    }

    const image = () => {
        return (
            <SimpleSlider
                pageDots
                shownSize= {1}
                isCart={true}
                index={scrollIndex}
                setIndex={setScrollIndex}
                dotsClassName={dotsClasses}
                infinite={true}
            >
                {productData.map((item, index) =>
                    <PictureImgix
                        key={`${item.imageName}-${index}`}
                        imageClassName={styles.image}
                        className={styles.imagePicture}
                        folder={item.imageFolder}
                        name={item.imageName}
                        alt={item.imageAlt}
                        widths={{
                            mobile: 335,
                            tablet: 328,
                            desktop: 538
                        }}
                        prefixOverride={{
                            tablet: 'none',
                            mobile: 'none',
                            desktop: 'none'
                        }}
                    />
                )}
            </SimpleSlider>
        )
    }

    const mobileView = () => {
        return (
            <>
                <div className={imageClasses}>
                    {image()}
                </div>
                <div className={contentClasses}>
                    {title()}
                </div>
                <div className={contentClasses}>
                    {content()}
                </div>
            </>
        )
    }

    const sideBySideView = () => {
        const contentSection = (
            <div className={contentClasses}>
                {title()}
                {content()}
            </div>
        )
        const imageSection = (
            <div className={imageClasses}>
                {image()}
            </div>
        )

        return (
            <>
                {imageSide === 'right'
                    ? [contentSection, imageSection]
                    : [imageSection, contentSection]
                }
            </>
        )
    }

    return (
        <section className={classes}>
            <div className="container row">
                {isMobile
                    ? mobileView()
                    : sideBySideView()
                }
            </div>
        </section>
    )
}

SliderByContent.propTypes = {
    backgroundColorClass: PropTypes.string,
    className: PropTypes.string,
    imageSide: PropTypes.oneOf(['right', 'left']),
    productCode: PropTypes.string,
    sectionName: PropTypes.string
}

export default React.memo(SliderByContent)
